html, body {
  overflow-x: hidden!important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #228BE6!important;
  overflow-x: hidden!important;
  height: 100%!important;
  position: relative!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white-paragraph {
  color: white!important;
}

.landing-page-text {
  font-size: 22px;
}

.overlap {
  /* position: relative!important;
  bottom: 200px!important;
  width: 50%;
  bottom: 50%;
  display: flex;
  align-items: center;
  justify-content: center; */
  display: flex!important;
  flex-shrink: 0;
  z-index: 2;
}

.overlap > input {
  text-align: center;
}

#root > div > div > div.ant-row.ant-row-center.center-div > div.overlap > input {
  border-right: 3px solid black!important;
}

.overlap-innards {
  width: 64px!important;
  height: 44px!important;
  padding: 0px!important;
  margin: 0px!important;
}

.overlap-borders {
  border: 3px solid #224C83!important;
  border-radius: 10px!important;
}

.ant-message-notice-content {
  bottom: 20px!important;
  top: initial!important;
}

.center-div {
  width: 100%!important;
  height: 300px!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.cloud-image {
  width: 100%;
  position: absolute!important;
}

.cloud-image > img {
  width: 100%!important;
  height: 100%!important;
}

/* Cloud image */
#root > div > div > div:nth-child(4) > div.ant-image.cloud-image > img {
  width: 140%!important;
  max-height: 290px!important;
  right: 20%!important;
  position: relative!important;
}

.landing-page-text > .white-paragraph {
  margin: 0px!important;
}

/* Cloudpark logo image */
.cloudpark-logo-box {
  justify-content: center;
  text-align: center;
}

.alert {
  z-index: 2!important;
}

.propark-logo > img {
  max-height: 30px!important;
  margin: 15px 0!important;
}

.animate-in {
  animation-name: slide-in;
  animation-duration: 0.75s;
  -webkit-animation-name: slide-in;
  -webkit-animation-duration: 0.75s;
}

.animate-out {
  animation-name: slide-out;
  animation-duration: 1.5s forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: slide-out;
  -webkit-animation-duration: 1.5s forwards;
  -webkit-animation-fill-mode: forwards;
}

/* Make the SuccessPage Skeleton's first element have a smaller top margin to align with "Location Name". */
div.receipt-box > div > div > ul.ant-skeleton-paragraph > li {
  margin: 4px 0 20px 0;
}
/* Make the rest of the Skeleton items scale downwards at the same rate as the left side field names. */
div.receipt-box > div > div > .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin: 8px 0 20px 0!important;
}

@keyframes slide-in {
  from { transform: translateX(-120vw) }
  to { transform: translateX(0%) }
}

@keyframes slide-out {
  from { transform: translateX(0%) }
  to { transform: translateX(120vw) }
}

.ant-btn-primary {
  background-color: #81C81E!important;
}